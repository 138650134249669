<template>
  <div id="home">
    <welcomeScreen />
    <aboutEvent />
    <program />
    <photoGallery />
    <afterMovies />
    <onePerformer />
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'

  import WelcomeScreen from '@/components/homepage/welcomeScreen.vue'
  import AboutEvent from '@/components/homepage/aboutEvent.vue'
  import program from '@/components/homepage/program.vue'
  import photoGallery from '@/components/homepage/photoGallery.vue'
  import afterMovies from '@/components/homepage/afterMovies.vue'
  import onePerformer from '@/components/homepage/onePerformer.vue'

  
  const route = ref(useRoute())
  /* onMounted(() => {}) */
</script>

<style>
.navbar.zero-bg {
    background-color: #ffffff00;
}
</style>