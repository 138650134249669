<template>
    <section id="one-performer">
        <div class="secondary-heading text-center">ESSENS TURKEY 2024</div>
        <h1 class="text-center mb-5"> Instagram Reels </h1>
        <div id="reels-container" class="container-fluid container-xxl px-5 position-relative">
            <!-- <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/392487362?h=c8cbd4ad52" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div> -->
            <swiper
                :modules="modules"
                :slides-per-view="1"
                :space-between="30"
                navigation
                :autoplay="swiperOptions.Autoplay"
                :breakpoints="swiperOptions.breakpoints"
            >
                <template v-for="reel in reels" :key="reel">
                    <swiper-slide class="my-2">
                        <div class="mb-3 d-flex gap-2 text-center align-items-center justify-content-center">
                            <a :href="reel.url" target="_blank"><img :src="reel.src"></a> 
                        </div>                                           
                                      
                    </swiper-slide>
                </template>
            </swiper>
        </div>
    </section>
</template>

<script>
    // import Swiper core and required modules
    import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';
    import 'swiper/css/autoplay';


    // Import Swiper styles
    export default {
        components: {
        Swiper,
        SwiperSlide,
        },
        setup() {
            const swiperOptions = {
                breakpoints: {
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                },
                Autoplay: {
                    pauseOnMouseEnter: true,
                    /* disableOnInteraction: false */
                }
            }

            const reels = [
                { src: 'https://static.essensworld.com/images/turkey_24/web/reels/insta_web_1.jpg', url: 'https://www.instagram.com/reel/C7lXBY4oGmF/?igsh=NmxkdXJ2Y3RmODls'},
                { src: 'https://static.essensworld.com/images/turkey_24/web/reels/insta_web_2.jpg', url: 'https://www.instagram.com/reel/C7g7jTMN7Eh/?igsh=Zm10djdrcDk0dXc3'},
                { src: 'https://static.essensworld.com/images/turkey_24/web/reels/insta_web_3.jpg', url: 'https://www.instagram.com/reel/C7eREWDInVp/?igsh=d3ppZXB1bGFxOWZl'},
                { src: 'https://static.essensworld.com/images/turkey_24/web/reels/insta_web_4.jpg', url: 'https://www.instagram.com/reel/C7bl6R6IDs-/?igsh=MWh6bW5rNDU3dWdpag'},
            ]

            return {
                modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
                swiperOptions,
                reels
            };
        },
        mounted() {
            const btnPrev = document.querySelector('.swiper-button-prev')
            const btnNext = document.querySelector('.swiper-button-next')
            const container = document.querySelector('#reels-container')
            container.append(btnPrev)
            container.append(btnNext)
        }
    };
</script>

<style>

    #one-performer {
        background-color: white;
    } 


    /* .swiper-slide {
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
    } */

    
    .swiper-button-next, .swiper-button-prev {
        top: calc(50% + 21px);
        z-index: 1;
        background: none !important;
    }

    .swiper-button-next {
        right: 5px;
    }
    .swiper-button-prev {
        left: 5px;
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        color: var(--secondary);
    }

    /* @media (max-width: 1399px) {
        .swiper-button-next {
            right: 5px;
        }
        .swiper-button-prev {
            left: 5px;
        }
    } */
</style>